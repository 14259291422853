@font-face {
    font-family: 'ManropeBold';
    src: url("Manrope-Bold.ttf");
}

@font-face {
    font-family: 'ManropeSemiBold';
    src: url("Manrope-SemiBold.ttf");
}

@font-face {
    font-family: 'ManropeRegular';
    src: url("Manrope-Regular.ttf");
}

@font-face {
    font-family: 'ManropeMedium';
    src: url("Manrope-Medium.ttf");
}

@font-face {
    font-family: 'ManropeExtraBold';
    src: url("Manrope-ExtraBold.ttf");
}
